
export default {
  data() {
    return {
      options: [
        {
          name: "logo",
          label: "Logo",
          type: "image",
          size: "170x68",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },

  methods: {
    $scrollIntoView(link, title) {
      if (!link || !title) return;

      const id = link.replace("#", "");
      let el;

      if (this.homepage) {
        el = document.getElementById(id);
        if (el) {
          el.scrollIntoView();
        }
      } else {
        {
          this.$scrollToTop();
          this.$router.push({ path: this.$homepageLink });

          setTimeout(() => {
            el = document.getElementById(id);

            if (el) {
              el.scrollIntoView();
            }
          }, 1000);
        }
      }
    },
  },
};
