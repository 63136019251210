
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    link: {
      type: [String, Object],
    },
    whiteBtn: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      let classes;
      if (this.disabled) {
        classes = "text-gray-400 border-gray-400 cursor-not-allowed";
        return classes;
      } else {
        if (this.whiteBtn) {
          classes =
            "text-white md:hover:text-primary-blue md:hover:bg-white border-white";
        } else {
          classes =
            "text-primary-blue md:hover:text-white bg-white md:hover:bg-primary-blue border-primary-blue";
        }
      }

      return classes;
    },
  },
};
