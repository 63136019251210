import { render, staticRenderFns } from "./primaryButton.vue?vue&type=template&id=2ae41882"
import script from "./primaryButton.vue?vue&type=script&lang=js"
export * from "./primaryButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconsArrow: require('/home/node/app/components/ui/icons/arrow.vue').default})
