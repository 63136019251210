
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    link: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      let classes;
      if (this.disabled) {
        classes = "text-gray-400 cursor-not-allowed";
        return classes;
      } else {
        classes = "text-primary-blue";
      }

      return classes;
    },
  },
};
