import Vue from "vue";

Vue.mixin({
  data() {
    return {
      homepage: "/",
      defaultLanguage: "",
    };
  },
  mounted() {
    if (
      this.$application &&
      this.$application.i18n &&
      this.$application.i18n.defaultLocale
    ) {
      this.defaultLanguage = this.$application.i18n.defaultLocale;
    }
  },
  computed: {
    $homepageLink() {
      if (this.$i18n.locale === this.defaultLanguage) {
        return "/";
      } else {
        return `/${this.$i18n.locale}`;
      }
    },
  },
  watch: {
    $route() {
      this.$checkIfHomepage();
    },
  },
  methods: {
    $checkIfHomepage() {
      if (this.$route && this.$route.path) {
        if (
          this.$route.path === "/" ||
          this.$route.path === "/naslovnica" ||
          this.$route.path === "/hr" ||
          this.$route.path === "/hr/naslovnica" ||
          this.$route.path === "/homepage" ||
          this.$route.path === "/en" ||
          this.$route.path === "/en/homepage"
        ) {
          this.homepage = true;
        } else this.homepage = false;
      }
    },

    $isInViewport(id, getClasses = false) {
      if (typeof document !== "undefined") {
        let targets;

        if (getClasses) {
          targets = document.getElementsByClassName(id);
        } else {
          targets = document.getElementById(id);
        }

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const intersecting = entry.isIntersecting;

              if (intersecting) {
                entry.target.classList.add("intersecting");
              } else if (
                !intersecting &&
                entry.target.classList.contains("intersecting")
              ) {
                entry.target.classList.remove("intersecting");
              }
            });
          },
          {
            threshold: 0.5,
          }
        );

        if (targets) {
          if (targets.length) {
            targets.forEach((target) => {
              observer.observe(target);
            });
          } else {
            observer.observe(targets);
          }
        }
      }
    },

    $formatDate(dateStr) {
      if (!dateStr) return;
      const locale = this.$i18n.locale;
      const date = new Date(dateStr);
      var region;

      switch (locale) {
        case "hr":
          region = "hr-HR";
          break;
        case "en":
          region = "en-EN";
          break;
        default:
          return;
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return date.toLocaleDateString(region, options);
    },

    $scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
});
