
import * as vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      top: true,
      menuOpen: false,
      switcherOpen: false,
      activeNavItem: null,
      intersectingElementID: null,

      options: [
        {
          name: "icon",
          label: "Ikona",
          type: "image",
          size: "24x24",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },
  mounted() {
    if (process.browser) {
      window.addEventListener("scroll", this.getPageYOffset);

      window.addEventListener("scroll", this.getIntersectingElement);
    }
  },
  watch: {
    $route() {
      this.closeMenu();

      if (!this.homepage) {
        this.intersectingElementID = null;
      }
    },
  },
  methods: {
    getPageYOffset() {
      if (window.pageYOffset < 250) {
        this.top = true;
      } else {
        this.top = false;
      }
    },
    toggleMenu() {
      const cb = document.getElementById("check");
      if (cb) {
        if (cb.checked) {
          this.menuOpen = true;
        } else {
          this.menuOpen = false;
        }
      }
    },
    closeMenu() {
      const cb = document.getElementById("check");

      if (cb) {
        this.menuOpen = false;
        cb.checked = false;
      }
    },

    $scrollIntoView(link, title, index) {
      if (!link || !title) return;

      this.activeNavItem = title;

      if (window.innerWidth < 1024) {
        this.closeMenu();
      }

      const id = link.replace("#", "");

      let el;

      if (this.homepage) {
        if (index === 0) {
          window.scrollTo(0, 0);
        } else {
          el = document.getElementById(id);
          if (el) {
            el.scrollIntoView();
          }
        }
      } else {
        this.$scrollToTop();
        this.$router.push({ path: this.$homepageLink });

        if (index !== 0) {
          setTimeout(() => {
            el = document.getElementById(id);

            if (el) {
              el.scrollIntoView();
            }
          }, 1000);
        }
      }
    },

    getIntersectingElement() {
      if (this.homepage) {
        const intersectingElements =
          document.getElementsByClassName("intersecting");

        this.activeNavItem = null;

        if (
          intersectingElements &&
          intersectingElements.length &&
          intersectingElements[0]
        ) {
          this.intersectingElementID = intersectingElements[0].id;
        } else {
          this.intersectingElementID = null;
        }
      }
    },
  },
};
