
export default {
  mounted() {
    if (document.readyState === "complete") {
      setTimeout(() => {
        this.removeAttribute();
      }, 500);
    }
  },
  methods: {
    removeAttribute() {
      const cookieEl = document.getElementById("cookie");

      if (cookieEl) {
        let links = cookieEl.getElementsByTagName("a");

        links = [...links];

        if (links && links.length) {
          links.forEach((link) => {
            link.removeAttribute("target");
          });
        }
      }
    },
  },
};
